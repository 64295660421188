import { api, RouterInput, RouterOutput } from "../../../services/rpc/RpcProvider";

export type ListParams = RouterInput["automations"]["list"];
export type FindParams = RouterInput["automations"]["find"];

export type Automation = RouterOutput["automations"]["list"][0];
export type AutomationStage = Automation["stage"];

export type AutomationStats = RouterOutput["automations"]["find"]["stats"];

export const useAutomations = (params: ListParams, options = {}) => {
  const { data: automations, refetch } = api.automations.list.useQuery(params, {
    refetchOnMount: "always",
    ...options
  });

  return {
    automations,
    refetch
  };
};

export const useAutomation = (params: FindParams) => {
  const { data: automation, refetch } = api.automations.find.useQuery(params, {
    refetchOnMount: "always"
  });

  return {
    automation,
    refetch
  };
};
