import { Employee, useEmployees } from "../../hooks/useEmployees";
import { SearchInput } from "../../../../components/molecules";
import {
  DateInput,
  Icon,
  IconButton,
  Input,
  Select,
  Stack,
  Title,
  Tooltip,
  useTheme
} from "@introist/react-foundation/v2";
import { EmployeeField, useEmployeeFields } from "../../hooks/useEmployeeFields";
import styled from "styled-components";
import { useMemo, useState } from "react";
import { FieldCategorySelector } from "../../components/FieldCategorySelector";
import { formatDate } from "../../../../utils/DatesUtils";
import { DateFormats } from "../../../../utils/dates";

const LimitTitle = styled.div`
  h5 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const FieldRow = styled(Stack)`
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: transparent;

  :hover {
    border-color: var(--palette-border-dimmed);
  }
`;

export type EmployeeProfileFormProps = {
  employee: Employee;
  onChange: (field: string, value: any) => void;
  errors?: any[];
};

export const EmployeeProfileForm = ({ employee, onChange, errors }: EmployeeProfileFormProps) => {
  const { theme } = useTheme();

  const { fields } = useEmployeeFields();

  const [category, setCategory] = useState("General");
  const [search, setSearch] = useState("");

  const employeeFields = useMemo(() => {
    if (!fields) return [];
    return fields.filter(f => f.type === "employee");
  }, [fields]);

  const updatedAtFields = useMemo(() => {
    if (!fields) return [];
    return fields.filter(f => f.key.endsWith("updatedat"));
  }, [fields]);

  const { employees } = useEmployees({ fields: (employeeFields ?? []).map(f => f.linkingField!) });

  const filteredFields = useMemo(() => {
    if (!employee) return undefined;

    return (fields ?? [])
      .filter(f => (f.category ?? "General") === category)
      .filter(f => !updatedAtFields.map(uf => uf.key).includes(f.key))
      .filter(
        field =>
          field.title.toLowerCase().includes(search.toLowerCase()) ||
          employee[field.key]?.toLowerCase().includes(search.toLowerCase())
      );
  }, [employee, search, fields, category, updatedAtFields]);

  return (
    <Stack vertical>
      <Stack justifyContent="space-between">
        <FieldCategorySelector value={category} onChange={setCategory} />
        <SearchInput value={search} onChange={setSearch} />
      </Stack>
      <Stack vertical gap="none">
        {(filteredFields ?? [])!.map((field, i) => {
          const updatedAtField = updatedAtFields.find(f => f.key === field.key + "updatedat");
          const updatedAt = updatedAtField ? employee[updatedAtField.key] : undefined;

          return (
            <FieldRow
              justifyContent="center"
              style={{
                padding: theme.spacing.large
              }}
            >
              <LimitTitle style={{ width: "30%" }}>
                <Tooltip tooltip={field.title}>
                  <Title>{field.title}</Title>
                </Tooltip>
              </LimitTitle>

              <Stack style={{ flex: 1 }}>
                <EmployeeProfileField
                  field={field}
                  value={employee[field.key] ?? null}
                  onChange={newVal => {
                    onChange(field.key, newVal);
                  }}
                  error={(errors ?? []).find(e => e.attribute === field.key)?.error}
                  employees={employees ?? []}
                />
                <div style={{ width: "20px" }}>
                  {updatedAtField && (
                    <Tooltip
                      tooltip={
                        updatedAt
                          ? `This field was last updated ${formatDate(
                              updatedAt,
                              DateFormats.date.long
                            )}`
                          : "This field has not been updated"
                      }
                    >
                      <Icon name="time" dimmed />
                    </Tooltip>
                  )}
                </div>
              </Stack>
            </FieldRow>
          );
        })}
      </Stack>
    </Stack>
  );
};

type EmployeeProfileFieldProps = {
  field: EmployeeField;
  value: string | null;
  onChange: (val: string | null) => void;
  hasChanges?: boolean;
  error?: string;
  employees: Employee[];
  updateTracking?: boolean;
  updatedAt?: string | null;
};

const EmployeeProfileField = ({
  field,
  value,
  onChange,
  hasChanges,
  error,
  employees,
  updateTracking,
  updatedAt
}: EmployeeProfileFieldProps) => {
  const { theme } = useTheme();

  const resolveField = () => {
    const fieldStyles = {
      flex: 1,
      background: hasChanges && !error ? theme.palette.surface.subdued : undefined
    };

    if (field.type === "date") {
      return (
        <DateInput
          size="small"
          variant="blended"
          style={fieldStyles}
          inputStyles={{ color: theme.palette.foreground.default }}
          endAdornment={<Icon name="calendar" color={theme.palette.foreground.dimmed} />}
          value={value ?? undefined}
          displayFormat={"DD.MM.yyyy"}
          onChange={val => onChange(val ?? null)}
          error={!!error}
          placeholder="-"
        />
      );
    }
    if (field.type === "employee") {
      const employee = employees.find(e => e[field.linkingField!] === value);
      const options = employees.map(e => ({
        key: e[field.linkingField!] ?? "",
        title: e.name!
      }));

      return (
        <Stack>
          <Select
            searchable
            size="small"
            variant="blended"
            startAdornment={<Icon name="person" />}
            style={{ ...fieldStyles, width: "100%" }}
            inputStyles={{ color: theme.palette.foreground.default }}
            options={options}
            value={value ?? undefined}
            onSelect={opt => onChange(opt.key)}
            error={!!error}
            onClear={() => onChange(null)}
            placeholder="-"
          />
          {employee && (
            <IconButton
              icon="moveTo"
              onClick={() => window.open(`/employees/${employee.id}#fields`, "_blank")}
              dimmed
            />
          )}
        </Stack>
      );
    }
    if (field.options) {
      return (
        <Select
          size="small"
          variant="blended"
          style={{ ...fieldStyles, width: "100%" }}
          inputStyles={{ color: theme.palette.foreground.default }}
          options={field.options.map(o => ({ key: o }))}
          value={value ?? undefined}
          onSelect={opt => onChange(opt.key)}
          error={!!error}
          placeholder="-"
        />
      );
    }
    return (
      <Input
        size="small"
        variant="blended"
        style={fieldStyles}
        inputStyles={{
          color: theme.palette.foreground.default
        }}
        value={value ?? ""}
        onChange={val => onChange(val)}
        error={!!error}
        placeholder="-"
      />
    );
  };

  return (
    <div style={{ flex: 3 }}>
      {resolveField()}
      {error && (
        <Title color={theme.palette.danger.default} style={{ marginTop: theme.spacing.small }}>
          {error}
        </Title>
      )}
    </div>
  );
};
